import React, { ReactElement } from "react";

export default function FleetTrackingQuotesLogo(): ReactElement {
    return (
        <svg
            width="270"
            height="30"
            viewBox="0 0 270 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.4406 24.8925V7.85974H40.1503V10.1907H32.983V15.3758H38.838V17.7251H32.983V24.8925H29.4406Z"
                fill="#129188"
            />
            <path
                d="M42.5089 24.8925V6.71265H45.9227V24.8925H42.5089Z"
                fill="#129188"
            />
            <path
                d="M59.9912 24.4704C58.5022 24.919 56.9577 25.1568 55.4027 25.177C54.4932 25.2268 53.583 25.0941 52.7257 24.7868C51.8683 24.4794 51.0811 24.0037 50.4103 23.3875C49.7843 22.7385 49.2984 21.9678 48.9826 21.1233C48.6669 20.2787 48.5281 19.3782 48.5749 18.4777C48.4948 16.7433 49.0977 15.0465 50.2543 13.7515C50.817 13.1464 51.5031 12.6693 52.2663 12.3526C53.0295 12.0359 53.8519 11.887 54.6777 11.9161C55.4445 11.8523 56.2151 11.979 56.9211 12.2848C57.6272 12.5906 58.2467 13.066 58.7248 13.6689C59.5752 14.8313 60.0035 16.6668 60.0096 19.1752H52.1723C52.4048 21.5857 53.7263 22.791 56.1369 22.791C57.4637 22.7536 58.774 22.4865 60.0096 22.0017L59.9912 24.4704ZM52.0989 17.3306H56.6875C56.6875 15.174 55.99 14.0911 54.6043 14.0911C53.2185 14.0911 52.3283 15.174 52.0989 17.3306Z"
                fill="#129188"
            />
            <path
                d="M73.3256 24.4704C71.837 24.9208 70.2922 25.1586 68.7371 25.177C67.8263 25.2265 66.9148 25.0937 66.0559 24.7864C65.1971 24.4791 64.4082 24.0035 63.7355 23.3875C63.1091 22.7388 62.6228 21.9682 62.3071 21.1235C61.9913 20.2789 61.8528 19.3782 61.9001 18.4777C61.8329 16.7386 62.4489 15.0423 63.6162 13.7515C64.181 13.1466 64.8689 12.6698 65.6335 12.3532C66.3981 12.0366 67.2217 11.8875 68.0488 11.9161C68.8155 11.8532 69.5858 11.9802 70.2917 12.2859C70.9976 12.5916 71.6173 13.0666 72.0959 13.6689C72.9524 14.8313 73.3776 16.6668 73.3715 19.1752H65.4517C65.6811 21.5888 67.0118 22.791 69.4254 22.791C70.7493 22.7542 72.0566 22.4871 73.2889 22.0017L73.3256 24.4704ZM65.4517 17.3306H69.9852C69.9852 15.174 69.2969 14.0911 67.902 14.0911C66.507 14.0911 65.6719 15.174 65.4517 17.3306Z"
                fill="#129188"
            />
            <path
                d="M82.8882 24.8191C82.2647 25.024 81.6165 25.1444 80.961 25.177C78.006 25.177 76.5285 23.7974 76.5285 21.0381V14.3847H75.1152V12.2556H76.5285V10.1265L79.9423 9.73193V12.2556H82.6496V14.3847H79.9515V20.5885C79.9515 22.1394 80.5847 22.9103 81.8512 22.9103C82.2048 22.8995 82.556 22.8472 82.8974 22.7543L82.8882 24.8191Z"
                fill="#129188"
            />
            <path
                d="M89.6884 24.8925V10.2091H83.5856V7.85974H99.3427V10.1907H93.2491V24.8741L89.6884 24.8925Z"
                fill="#129188"
            />
            <path
                d="M101.242 24.8925V12.2556H104.647V14.6325C104.971 13.8329 105.529 13.15 106.248 12.6734C106.967 12.1968 107.813 11.9488 108.676 11.9619C108.889 11.9637 109.101 11.9883 109.309 12.0353V15.073C108.878 14.9078 108.422 14.8178 107.96 14.8068C107.273 14.8191 106.602 15.0176 106.018 15.3812C105.435 15.7449 104.961 16.26 104.647 16.8717V24.8925H101.242Z"
                fill="#129188"
            />
            <path
                d="M117.697 23.5343C117.225 24.0388 116.658 24.444 116.027 24.7261C115.397 25.0082 114.717 25.1615 114.026 25.1771C113.533 25.1982 113.041 25.1197 112.579 24.9462C112.117 24.7728 111.695 24.5079 111.337 24.1676C110.991 23.8265 110.72 23.4168 110.541 22.9648C110.363 22.5128 110.281 22.0285 110.3 21.5429C110.274 20.9058 110.414 20.2728 110.707 19.7064C111 19.1399 111.435 18.6596 111.97 18.3126C113.425 17.4743 115.094 17.0818 116.77 17.1838H117.688V16.0275C117.688 14.7151 116.935 14.0636 115.439 14.0636C114.022 14.0878 112.634 14.4765 111.411 15.1923V12.843C112.871 12.2588 114.426 11.9477 115.999 11.9253C119.309 11.9253 120.964 13.2437 120.964 15.8806V21.4695C120.964 22.4606 121.285 22.9562 121.882 22.9562C122.033 22.9514 122.183 22.9361 122.331 22.9103L122.414 24.8191C121.797 25.0191 121.154 25.1273 120.505 25.1403C119.936 25.211 119.36 25.0872 118.87 24.7889C118.38 24.4907 118.006 24.0356 117.807 23.4976L117.697 23.5343ZM117.697 21.6989V19.166H116.889C114.687 19.166 113.586 19.8543 113.586 21.2401C113.58 21.4581 113.62 21.675 113.702 21.8771C113.784 22.0792 113.907 22.2622 114.063 22.4147C114.217 22.571 114.402 22.6939 114.605 22.776C114.809 22.8581 115.027 22.8975 115.247 22.8919C116.185 22.8258 117.062 22.4021 117.697 21.7081V21.6989Z"
                fill="#129188"
            />
            <path
                d="M134.216 24.6172C132.936 24.9731 131.616 25.1613 130.288 25.177C129.412 25.2238 128.536 25.0907 127.714 24.7861C126.892 24.4815 126.141 24.0118 125.507 23.4058C124.904 22.7573 124.438 21.9941 124.137 21.1615C123.836 20.329 123.706 19.4443 123.754 18.5603C123.707 17.6695 123.845 16.7786 124.161 15.9443C124.477 15.1101 124.964 14.3508 125.589 13.7148C126.264 13.1069 127.053 12.6389 127.91 12.3381C128.767 12.0372 129.675 11.9093 130.582 11.962C131.784 11.9856 132.979 12.152 134.142 12.4575V14.9262C133.063 14.5317 131.931 14.3027 130.784 14.2471C130.31 14.2326 129.839 14.3317 129.411 14.536C128.983 14.7404 128.611 15.044 128.324 15.4217C127.668 16.325 127.344 17.4272 127.406 18.542C127.351 19.6901 127.709 20.8201 128.416 21.7264C128.743 22.1179 129.155 22.429 129.622 22.6358C130.088 22.8427 130.595 22.9397 131.105 22.9195C132.19 22.9062 133.262 22.6818 134.262 22.2587L134.216 24.6172Z"
                fill="#129188"
            />
            <path
                d="M136.969 24.8925V6.71265H140.374V18.1473H140.594L145.091 12.2556H147.926L143.769 17.6609L149.22 24.8925H145.082L140.594 18.5695H140.374V24.8925H136.969Z"
                fill="#129188"
            />
            <path
                d="M151.414 10.1265V7.28162H154.818V10.1265H151.414ZM151.414 24.8925V12.2556H154.818V24.8925H151.414Z"
                fill="#129188"
            />
            <path
                d="M158.609 24.8926V12.2557H162.013V14.6325C162.424 13.8414 163.041 13.1758 163.798 12.7058C164.556 12.2358 165.426 11.9789 166.317 11.962C166.814 11.937 167.31 12.02 167.771 12.2054C168.232 12.3908 168.647 12.6741 168.988 13.0357C169.676 13.8507 170.028 14.8979 169.97 15.9632V24.8926H166.565V16.7983C166.565 15.3667 166.088 14.6509 165.134 14.6509C164.051 14.6509 163.014 15.4126 162.013 16.936V24.8926H158.609Z"
                fill="#129188"
            />
            <path
                d="M173.742 28.8203L174.017 26.3425C175.272 26.933 176.64 27.246 178.027 27.2602C179.013 27.3551 179.998 27.0695 180.78 26.4618C181.088 26.1045 181.321 25.6887 181.465 25.2392C181.608 24.7898 181.66 24.3161 181.616 23.8463V22.1669C181.307 23.0001 180.744 23.7147 180.006 24.209C179.267 24.7033 178.392 24.9522 177.504 24.9201C176.816 24.9439 176.133 24.8031 175.51 24.5094C174.888 24.2157 174.344 23.7776 173.925 23.2315C172.975 21.897 172.509 20.2783 172.604 18.6429C172.516 16.9006 173.042 15.1829 174.09 13.7882C174.559 13.1958 175.161 12.7212 175.846 12.4025C176.531 12.0839 177.281 11.9298 178.037 11.9528C178.707 11.9654 179.369 12.1105 179.983 12.3798C180.597 12.6492 181.152 13.0374 181.616 13.5221L181.973 12.2465H185.039V21.9192C185.087 23.3168 184.97 24.7154 184.69 26.0856C184.46 26.9301 183.984 27.6872 183.323 28.2605C181.956 29.3203 180.249 29.8456 178.523 29.7381C176.889 29.7036 175.272 29.3934 173.742 28.8203V28.8203ZM181.597 20.1847V15.4952C181.294 15.0894 180.907 14.7544 180.462 14.5135C180.016 14.2726 179.524 14.1316 179.018 14.1002C178.604 14.0972 178.196 14.2044 177.836 14.4108C177.477 14.6172 177.178 14.9154 176.972 15.2749C176.421 16.2325 176.156 17.3283 176.21 18.4318C176.21 20.8791 177.006 22.1027 178.596 22.1027C179.679 22.1455 180.67 21.5062 181.57 20.1847H181.597Z"
                fill="#129188"
            />
            <path
                d="M204.861 28.3431C202.235 27.715 199.719 26.6908 197.4 25.3055C196.84 25.3055 196.427 25.3606 196.152 25.3606C195.001 25.402 193.855 25.1984 192.788 24.7633C191.722 24.3282 190.76 23.6716 189.967 22.8369C188.377 21.0841 187.54 18.777 187.636 16.4129C187.573 15.2244 187.748 14.0354 188.15 12.9151C188.552 11.7949 189.172 10.7658 189.976 9.88795C191.706 8.3286 193.952 7.46558 196.281 7.46558C198.609 7.46558 200.856 8.3286 202.585 9.88795C204.205 11.6648 205.053 14.0114 204.944 16.4129C204.944 20.2734 203.414 22.9684 200.355 24.4979C202.554 25.26 204.832 25.7711 207.146 26.0213L204.861 28.3431ZM196.226 23.0204C196.926 23.0421 197.621 22.89 198.249 22.5777C198.877 22.2655 199.418 21.8028 199.823 21.2309C200.78 19.8027 201.247 18.1021 201.154 16.3854C201.24 14.6895 200.773 13.0116 199.823 11.6041C199.412 11.0499 198.878 10.5996 198.262 10.2893C197.646 9.97908 196.966 9.81747 196.276 9.81747C195.586 9.81747 194.906 9.97908 194.29 10.2893C193.674 10.5996 193.14 11.0499 192.729 11.6041C191.784 13.0237 191.321 14.7097 191.408 16.4129C191.326 18.1098 191.789 19.7883 192.729 21.2033C193.119 21.7657 193.643 22.2221 194.253 22.5313C194.864 22.8405 195.542 22.9928 196.226 22.9745V23.0204Z"
                fill="#129188"
            />
            <path
                d="M215.461 24.8925V22.5065C215.052 23.2995 214.436 23.9667 213.678 24.437C212.92 24.9073 212.049 25.1631 211.157 25.177C210.661 25.2014 210.166 25.1189 209.706 24.9352C209.245 24.7516 208.829 24.471 208.486 24.1125C207.798 23.2975 207.446 22.2503 207.504 21.185V12.2556H210.918V20.3498C210.918 21.7815 211.395 22.4973 212.35 22.4973C213.423 22.4973 214.46 21.7356 215.461 20.203V12.2556H218.866V24.8925H215.461Z"
                fill="#129188"
            />
            <path
                d="M227.96 25.1771C227.102 25.2147 226.245 25.0752 225.443 24.7674C224.641 24.4595 223.911 23.9898 223.298 23.3875C222.701 22.7413 222.239 21.9821 221.939 21.1547C221.64 20.3273 221.509 19.4483 221.554 18.5695C221.506 17.689 221.635 16.8078 221.935 15.9784C222.234 15.149 222.698 14.3885 223.298 13.7424C224.629 12.6025 226.323 11.976 228.075 11.976C229.827 11.976 231.521 12.6025 232.851 13.7424C233.455 14.383 233.923 15.1396 234.225 15.9661C234.528 16.7927 234.66 17.6721 234.613 18.5512C234.694 19.4414 234.577 20.3384 234.273 21.1787C233.968 22.019 233.483 22.7822 232.851 23.4142C232.219 24.0463 231.455 24.5317 230.615 24.8364C229.775 25.141 228.878 25.2573 227.988 25.1771H227.96ZM228.024 23.048C229.976 23.048 230.952 21.549 230.952 18.5512C231.011 17.4139 230.744 16.2834 230.181 15.2933C229.963 14.9214 229.65 14.6141 229.274 14.4029C228.899 14.1916 228.474 14.084 228.043 14.0911C227.614 14.0844 227.192 14.1924 226.819 14.4037C226.446 14.6151 226.137 14.9222 225.923 15.2933C225.351 16.287 225.081 17.425 225.143 18.5695C225.084 19.7126 225.352 20.8486 225.914 21.8458C226.125 22.2168 226.433 22.5241 226.804 22.7356C227.176 22.947 227.597 23.0549 228.024 23.048Z"
                fill="#129188"
            />
            <path
                d="M243.754 24.8191C243.134 25.0244 242.488 25.1448 241.836 25.177C238.881 25.177 237.403 23.7974 237.403 21.0381V14.3847H235.99V12.2556H237.403V10.1265L240.808 9.73193V12.2556H243.488V14.3847H240.781V20.5885C240.781 22.1394 241.414 22.9103 242.68 22.9103C243.034 22.9001 243.385 22.8478 243.726 22.7543L243.754 24.8191Z"
                fill="#129188"
            />
            <path
                d="M256.611 24.4704C255.122 24.9208 253.578 25.1586 252.022 25.177C251.112 25.2265 250.2 25.0937 249.341 24.7864C248.482 24.4791 247.694 24.0035 247.021 23.3875C246.394 22.7388 245.908 21.9682 245.592 21.1235C245.277 20.2789 245.138 19.3782 245.186 18.4777C245.106 16.745 245.705 15.0494 246.856 13.7515C247.421 13.1466 248.108 12.6698 248.873 12.3532C249.638 12.0366 250.461 11.8875 251.288 11.9161C252.055 11.8532 252.825 11.9802 253.531 12.2859C254.237 12.5916 254.857 13.0666 255.335 13.6689C256.186 14.8313 256.611 16.6668 256.611 19.1752H248.774C249.003 21.5888 250.334 22.791 252.747 22.791C254.071 22.7542 255.379 22.4871 256.611 22.0017V24.4704ZM248.728 17.3306H253.261C253.261 15.174 252.573 14.0911 251.178 14.0911C249.783 14.0911 248.994 15.174 248.728 17.3306Z"
                fill="#129188"
            />
            <path
                d="M259.327 24.4887V21.9925C260.695 22.6232 262.173 22.9816 263.677 23.0479C265.072 23.0479 265.77 22.5707 265.77 21.6254C265.77 21.0106 265.201 20.4691 264.044 20.0102L262.897 19.5514C261.898 19.2046 260.984 18.6476 260.218 17.9179C259.676 17.329 259.386 16.5517 259.41 15.7521C259.392 15.2158 259.508 14.6835 259.746 14.203C259.985 13.7224 260.339 13.3087 260.777 12.9989C261.909 12.2603 263.245 11.8974 264.595 11.9619C265.831 11.9897 267.06 12.1403 268.266 12.4116V14.8068C267.18 14.386 266.034 14.1443 264.87 14.091C263.457 14.091 262.751 14.5315 262.751 15.4033C262.751 15.9815 263.274 16.4679 264.32 16.8717L265.302 17.2479C266.436 17.6064 267.487 18.1866 268.394 18.9549C268.685 19.2451 268.912 19.5922 269.062 19.9743C269.212 20.3565 269.282 20.7655 269.266 21.1757C269.275 21.7417 269.145 22.3012 268.888 22.8052C268.63 23.3092 268.253 23.7423 267.789 24.0665C266.661 24.8495 265.306 25.2397 263.934 25.177C262.374 25.1706 260.822 24.9387 259.327 24.4887V24.4887Z"
                fill="#129188"
            />
            <path
                d="M20.7682 8.8968C20.5173 7.23818 19.8712 5.66422 18.8844 4.30766C17.8976 2.95111 16.599 1.85173 15.0983 1.10225C13.5975 0.35277 11.9385 -0.0248399 10.2612 0.00126728C8.58394 0.0273745 6.93751 0.456433 5.4608 1.25226C4.2216 1.89257 3.12854 2.78274 2.25057 3.86662C1.37261 4.95049 0.7288 6.20456 0.359747 7.54971C-0.00930535 8.89485 -0.0955868 10.3019 0.106346 11.682C0.308278 13.0622 0.79404 14.3855 1.53299 15.5686V15.5686C1.53299 15.6145 1.59723 15.6603 1.62477 15.7154C1.6523 15.7705 1.79913 15.9815 1.90008 16.11C2.69177 17.2189 3.59592 18.243 4.59815 19.166L7.98451 22.4606C8.62861 23.0967 9.49742 23.4534 10.4027 23.4534C11.3079 23.4534 12.1767 23.0967 12.8209 22.4606L15.8952 19.3128C16.3082 18.9182 16.7211 18.5144 17.1066 18.0923L17.3911 17.8261C19.4126 15.79 20.6286 13.0907 20.8141 10.2275C20.8233 9.81451 20.8416 9.35565 20.7682 8.8968ZM10.6734 19.065C8.9527 19.094 7.26098 18.62 5.80586 17.7012C4.35075 16.7823 3.19558 15.4586 2.48213 13.8925C1.76868 12.3264 1.528 10.5861 1.78963 8.88518C2.05126 7.18424 2.80382 5.5967 3.95493 4.31741C5.10605 3.03812 6.60563 2.12276 8.26961 1.68367C9.9336 1.24458 11.6896 1.30087 13.322 1.84564C14.9545 2.39041 16.3923 3.39995 17.4591 4.75034C18.526 6.10074 19.1753 7.73322 19.3274 9.44743C19.4318 10.6522 19.2883 11.8656 18.9058 13.0129C18.5233 14.1601 17.9099 15.2168 17.1034 16.1179C16.2968 17.019 15.3143 17.7454 14.2164 18.2522C13.1184 18.7591 11.9282 19.0357 10.7193 19.065H10.6734Z"
                fill="#129188"
            />
            <path
                d="M12.3253 27.9852C12.3277 28.249 12.2777 28.5107 12.1782 28.755C12.0786 28.9994 11.9315 29.2215 11.7453 29.4085C11.5592 29.5955 11.3378 29.7436 11.0939 29.8443C10.85 29.945 10.5885 29.9962 10.3247 29.995C10.0389 30.0011 9.75485 29.9497 9.48938 29.8438C9.22392 29.7378 8.9825 29.5795 8.77953 29.3782C8.57656 29.177 8.4162 28.9369 8.30799 28.6723C8.19979 28.4078 8.14596 28.1241 8.14972 27.8383C8.15784 27.5635 8.22103 27.2931 8.33552 27.0431C8.45002 26.7931 8.6135 26.5686 8.8163 26.3829C9.0191 26.1972 9.25709 26.0541 9.51618 25.962C9.77527 25.87 10.0502 25.8308 10.3247 25.8469C10.599 25.8476 10.8703 25.9044 11.1218 26.0139C11.3734 26.1234 11.5998 26.2833 11.7872 26.4836C11.9746 26.6839 12.1191 26.9205 12.2116 27.1787C12.3042 27.4369 12.3429 27.7114 12.3253 27.9852Z"
                fill="#0E6158"
            />
            <path
                d="M10.3889 16.7983C9.09683 16.7673 7.84288 16.3543 6.78523 15.6115C5.72758 14.8686 4.9136 13.8292 4.44598 12.6243C3.97836 11.4194 3.87804 10.1031 4.15767 8.84122C4.4373 7.57938 5.08436 6.42862 6.01723 5.53408C6.9501 4.63955 8.12699 4.04132 9.39944 3.81486C10.6719 3.58839 11.9829 3.74383 13.1671 4.26158C14.3514 4.77932 15.3557 5.63617 16.0535 6.72404C16.7514 7.81192 17.1114 9.08209 17.0882 10.3743C17.0425 12.1115 16.3133 13.7606 15.059 14.9633C13.8047 16.1661 12.1265 16.8255 10.3889 16.7983Z"
                fill="url(#paint0_linear_9_1136)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_9_1136"
                    x1="3.76303"
                    y1="10.255"
                    x2="17.079"
                    y2="10.255"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F89523" />
                    <stop offset="0.1" stopColor="#F68324" />
                    <stop offset="0.25" stopColor="#F46F25" />
                    <stop offset="0.43" stopColor="#F26125" />
                    <stop offset="0.64" stopColor="#F15826" />
                    <stop offset="1" stopColor="#F15626" />
                </linearGradient>
            </defs>
        </svg>
    );
}
